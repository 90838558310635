










import Vue from "vue";

export default Vue.extend({
  name: "ModalContainer",
  props: {
    text: {
      type: String,
      required: false,
      default: "",
    },
    modalTitle: {
      type: String,
      required: true,
    },
    showButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    id: {
      type: String,
      required: false,
      default: "modal-container",
    },
  },
});
